import React, { useState, useEffect } from 'react';
import GrainIcon from '@mui/icons-material/Grain';
import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Userdata } from 'src/models/crypto_order';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import axios from 'axios';
import CommonUserModal from 'src/common/commonUserModal';
import { useNavigate } from 'react-router';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CommonTable from 'src/common/table/table';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import DashboardAction from 'src/action/DashboardAction';
import { callApi } from 'src/action/RestClient';

interface FetchOptions {
  page?: number;
  searchQuery?: string;
  perpage?: number;
  sort?: string;
  sortBy?: string;
  startDate?: string;
  endDate?: string;
  searchTerm?: string;
  recuringStatus?: any;
  recurring?: any;
}

const GetSurveyPageUsers = () => {
  const renderActionsCell = (params: any) => {
    const user = params.row;
    return (
      <div>
         <Tooltip
          title={`View Survey Detail`}
          arrow
           onClick={() =>  navigate(`/management/surveyDetail/${user?._id?.$oid}`)}
        >
          <IconButton color="inherit" size="small">
            <PreviewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title="Delete User"
          arrow
          onClick={() => deleteUserData(user._id)}
        >
          <IconButton color="inherit" size="small">
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </div>
    );
  };

  const deleteUserData = async (id: string) => {
    // await getAllData();
    if (auth.current === true) {
      setIsAuth(true);
    } else {
      setUserDeleteId(id);
      setDeleteModal(true);
    }
  };
;

  const phoneCall = (params: any) => {
    const status = params.row.phone;
    if (status) {
      return <span>{status}</span>;
    } else {
      return <span>{'--'}</span>;
    }
  };

  const siteCall = (params: any) => {
    const status = params.row.siteName;
    if (status != null) {
      return <span>{status}</span>;
    } else {
      return <span>{'--'}</span>;
    }
  };

  const formatDateCell = (params: any) => {
    const dateString = params?.value;
    const date = moment(dateString);
    const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
    const timeAgo = date.fromNow();
    return (
      <div>
        <p className="fw-bold">
          {formattedDate} <br></br>
          <small className="fw-normal">({timeAgo})</small>
        </p>
      </div>
    );
  };

  const columns = [
    { field: 'name', label: 'User Name', width: 240, align: 'left' },
    { field: 'email', label: 'Email', width: 240, align: 'left' },
    {
      field: 'phone',
      label: 'Phone Number',
      width: 240,
      renderCell: phoneCall,
      align: 'left',
    },
    {
      field: 'organization',
      label: 'Organization',
      width: 240,
      renderCell: siteCall,
      align: 'left',
    },
    {
      field: 'created_at',
      label: 'Registration Date',
      width: 240,
      renderCell: formatDateCell,
      align: 'left',
    },
    {
      field: 'actions',
      label: 'Actions',
      width: 240,
      renderCell: renderActionsCell,
      align: 'left',
    },
  ];

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userDeleteId, setUserDeleteId] = useState<String>();
  const [userLoading, setUserLoading] = useState<Boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const auth = React.useRef(false);
  const token = localStorage.getItem('token') || '';
  const [modalData, setModalData] = useState('');
  const [openDeleteModal, setDeleteModal] = useState<Boolean>(false);
  const [userAnswer, setUserAnswer] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRecoard, setTotalRecoard] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [startDate, setStartdate] = useState(null);
  const [endDate, SetendDate] = useState(null);
  const [sortby, setSortBy] = useState('created_at');
  const [sort, setSort] = useState('desc');
  const [searchTerm, setSearchTerm] = useState(null);
  const [recurringValue, setRecurringValue] = useState(null);
  const [recurringStatus, setRecurringStatus] = useState(null);

  const getAllData = () => {
    setUserLoading(true);
    const apiEndpoint = `${baseUrl}/get/survey`;
    const authtoken = token;
    let options: FetchOptions = {};
    if (page !== undefined) options.page = page + 1;
    if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
    if (sort !== null) options.sort = sort;
    if (sortby !== null) options.sortBy = sortby;
    if (startDate !== null) options.startDate = startDate;
    if (endDate != null) options.endDate = endDate;
    if (searchTerm != null) options.searchTerm = searchTerm;
    if (recurringValue != null) options.recurring = recurringValue;
    if (recurringStatus != null) options.recuringStatus = recurringStatus;
    return axios
      .get(apiEndpoint, {
        headers: {
          accept: '*/*',
          'Auth-Token': authtoken,
        },
        params: options,
      })
      .then((response) => {
        const data = response.data.data;
        setTotalRecoard(
          response?.data?.data ? response?.data?.data?.length : 0
        );
        setTotalData(response?.data?.data ? response?.data?.data?.length : 0);
        setUserData(data);
        setIsAuthenticated(false);
        setUserLoading(false);
      })
      .catch((error) => {
        setIsAuthenticated(true);
        auth.current = true;
        setUserLoading(false);
        if (error.response && error.response.status === 401) {
          DashboardAction.checklogoutService();
        }
      });
  };

  useEffect(() => {
    if (initialLoad) {
      getAllData();
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      getAllData();
    }
  }, [
    page,
    rowsPerPage,
    sort,
    sortby,
    startDate,
    endDate,
    searchTerm,
    recurringValue,
    recurringStatus,
  ]);

  useEffect(() => {
    if (!initialLoad) {
      getAllData();
      setUserAnswer(false);
    }
  }, [userAnswer]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth(true);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (search === '') {
      setFilteredData(originalData);
    } else {
      const newFilteredData = originalData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(newFilteredData);
    }
  }, [search]);

  const handleSort = (sortby, sort) => {
    setSortBy(sortby);
    setSort(sort);
  };

  const handleSearch = (search) => {
    setSearchTerm(search);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPage(data);
    setPage(0);
  };

  const handleDateChange = (date: any) => {
    setDateValue(date);
    const { start, end } = convertTimestampsToDates(date);
    setStartdate(start);
    SetendDate(end);
  };

  function convertTimestampsToDates(timestamps: any): {
    start: string;
    end: string | null;
  } {
    const [startTimestamp, endTimestamp] = timestamps;
    const formatDateString = (timestamp: number): string => {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    if (startTimestamp && endTimestamp) {
      return {
        start: formatDateString(startTimestamp),
        end: formatDateString(endTimestamp),
      };
    } else if (startTimestamp) {
      return {
        start: formatDateString(startTimestamp),
        end: formatDateString(startTimestamp),
      };
    } else {
      return {
        start: '',
        end: '',
      };
    }
  }

  return (
    <>
      <CommonUserModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={modalData}
      />
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <section
                className="breadcrum-section"
                style={{ marginTop: '20px', marginLeft: '20px' }}
              >
                <div role="presentation" style={{ marginTop: '20px' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="inherit"
                      href="/"
                    >
                      <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Users
                    </Link>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="text.primary"
                    >
                      <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                     Survey User Accounts
                    </Typography>
                  </Breadcrumbs>
                </div>
              </section>

              <div className="iq-card position-relative rounded overflow-hidden">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4
                      className="card-title mb-0 fw-bold"
                      style={{ fontSize: '1.1rem' }}
                    >
                      Users
                    </h4>
                  </div>
                  <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end">

                    <div className="datepicker-box">
                      <div className="input-group flex-nowrap">
                        <DatePicker
                          value={dateValue}
                          onChange={handleDateChange}
                          range
                          placeholder="Search by registration date"
                          className="form-control"
                          maxDate={new Date()}
                        />
                        <div
                          className="rounded-end py-2 px-3"
                          style={{ backgroundColor: 'rgb(40, 38, 33)' }}
                        >
                          <i className="fas fa-calendar text-light"></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="input-group"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      <div className="form-outline" data-mdb-input-init>
                        <input
                          type="text"
                          id="search"
                          name="search"
                          className="form-control"
                          value={search}
                          placeholder="Search user by name"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn"
                        style={{ backgroundColor: '#282621' }}
                        data-mdb-ripple-init
                        onClick={() => handleSearch(search)}
                      >
                        <i className="fas fa-search text-light"></i>
                      </button>
                    </div>
                   
                  </div>
                </div>
                <Box
                  sx={{
                    height: 700,
                    width: '100%',
                    background: 'white',
                    paddingBottom: '41px',
                  }}
                >
                  <CommonTable
                    data={userData}
                    columns={columns}
                    enablePagination
                    type={'user'}
                    loading={userLoading}
                    page={page}
                    count={totalRecoard}
                    rowsPerPage={rowsPerPage}
                    handleSort={handleSort}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetSurveyPageUsers;
