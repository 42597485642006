import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Enhanced_User from './users/Enhanced_User';
import GetAll_Sites from './sites/GetAll_Sites';
// import Add_User from './users/Add_User';
import Update_User from './users/Create_User';
import Create_Site from './sites/Create_Site';
import Create_User from './users/Create_User';
import Enhanced_GetSites from './sites/Enhanced_GetSites';
import About_us from './Page Variations/About-us/Add_about';
import Sites_Onboarding from './site managment/Sites_Onboarding';
import Sites_Requesting from './site managment/Sites_Requesting';
import Detailed_Site from './sites/Detailed_Site';
import Queue_Sites from './sites/Current_Queue';
import Admin from './admin-management/Admin';
import Bulk_Upload from './site managment/Bulk_Upload';
import Free_Text from './Page Variations/algorithms/Free_text';
import Add_Free_Text from './Page Variations/algorithms/Add_Free_Text';
import Add_Setting from './Configuration/Add_Setting';
import Configuration from './Configuration/Configuration';
import User_Queue_List from './sites/User_Queue_List';
import Download_Reports from './sites/Download_Reports';
import Framework from './Configuration/Framework';
import GetAll_Users from './users/GetAll_Users';
import Current_Queue from './sites/Current_Queue';
import Historic_Queue from './sites/Historic_Queue';
import CreateAdmin from './admin-management/CreateAdmin';
import UpdateAdmin from './admin-management/UpdateAdmin';
import TopSites from './sites/Top_Sites';
import UsersSites from './sites/Users_Sites';
import SystemHealth from './Page Variations/SystemHealth';
import LastLoggedIn from './users/LastLoggedIn';
import UsersAccount from './users/UsersAccount';
import AgencyAccounts from './users/AgencyAccounts';
import PendingAgencyAccounts from './users/pendingAgencyAccounts';
import RequestAgencyAccounts from './users/requestedAgencyAccount';
import ProcessedHistory from './Page Variations/Engine/processedHistory';
import UpcomingQueue from './Page Variations/Engine/upcomingQueue';
import ManualProcessing from './Page Variations/Engine/manualProcessing';
import Performance from './Page Variations/Engine/Performance';
import AllReports from './Page Variations/Archives/allreports';
import OverView from './Page Variations/BillingAndSubscriptions/overview';
import ManagePackages from './Page Variations/BillingAndSubscriptions/managePackages/managePackages';
import UpcomingInvoices from './Page Variations/BillingAndSubscriptions/upcomingInvoices';
import PaidInvoices from './Page Variations/BillingAndSubscriptions/paidInvoices';
import DelayedInvoices from './Page Variations/BillingAndSubscriptions/delayedInvoices';
import Questionnaire from './Page Variations/algorithms/questionnaire';
import Scoring from './Page Variations/algorithms/scoring';
import RolesAndPermissions from './Page Variations/Management/rolesAndPermissions';
import ConfigurationNew from './Page Variations/Management/configuration';
import CreateManagePackages from './Page Variations/BillingAndSubscriptions/managePackages/createManagePackage';
import SearchLogsSites from './sites/SearcchLogs';
import UpdateRequestedAgencyAccount from './users/updateRequestedAgencyAccount';
import UpdatePendingAgencyAccount from './users/updatePendingAgencyAccount';
import Question from './Page Variations/algorithms/question';
import CreateSites from './sites/createSite';
import ForgotPassword from './auth/forgotPassword';
import AdminResetPassword from './auth/adminResetPassword';
import SubscriptionDetails from './users/SubscriptionDetails';
import PaymentDetails from './users/PaymentDetails';
import InvoiceDetails from './users/InvoiceDetail';
import GetAllPaidUsers from './users/GetAllPaidUsers';
import MileStoneReport from './sites/mileStoneReport';
import MilestoneReport from './sites/mileStoneReport';
import MileStoneList from './sites/mileStoneList';
import MileStoneHistory from './sites/mileStoneHistory';
import CreateMilestoneReport from './sites/createMileStoneReport';
import UnCompletedPurchases from './users/unCompletedPurchases';
import GetAllLandingPageUsers from './users/GetLandingPageUsers';
import SiteRankingFields from './sites/siteRankingField';
import ManageTax from './Page Variations/BillingAndSubscriptions/manageTax/manageTax';
import CreateManageTax from './Page Variations/BillingAndSubscriptions/manageTax/createManageTaxes';
import UpdateQuestion from './Page Variations/algorithms/updateQuction';
import InvoiceDetailsList from './users/invoiceDetailList';
import UnCompletedPurchasesDetails from './users/unCompletePurchaseDetails';
import CreateCustomPackages from './Page Variations/BillingAndSubscriptions/managePackages/createCustomPakage';
import GetAllFreeUsers from './users/GetAllFreeUserAccount';
import AssignFreeUserPackage from './users/assignFreeuserPackage';
import ManageCountry from './Page Variations/MastersManagement/manageCountry';
import ManageStates from './Page Variations/MastersManagement/manageState';
import CreateCountryState from './Page Variations/MastersManagement/createState';
import GetSurveyPageUsers from './users/GetSurveyPageUser';
import DetailedSurveyUsers from './users/DetailedSurveyUsers';

// import Detailed_User from './users/Detailed_User';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const Detailed_User = Loader(lazy(() => import('src/users/Detailed_User')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

//authorization

const Login_Page = Loader(
  lazy(() => import('src/auth/Login'))
);


// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);

const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes = [
  // { path: '/forgotPassword', element: <ForgotPassword /> },
  {

    path: '',
    element: <BaseLayout />,
    children: [
      { path: '/', element: <Login_Page /> },
      { path: '/forgotPassword', element: <ForgotPassword /> },
      { path: '/resetPassword/:resetPasswordToken', element: <AdminResetPassword /> },
      { path: 'overview', element: <Navigate to="/" replace /> },
      {
        path: 'status',
        children: [
          { path: '', element: <Navigate to="404" replace /> },
          { path: '404', element: <Status404 /> },
          { path: '500', element: <Status500 /> },
          { path: 'maintenance', element: <StatusMaintenance /> },
          { path: 'coming-soon', element: <StatusComingSoon /> }
        ]
      },
      { path: '*', element: <Status404 /> }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      { path: '', element: <Navigate to="dashboard" replace /> },
      { path: 'dashboard', element: <Crypto /> },
      { path: 'systemHealth', element: <SystemHealth /> },
      { path: 'messenger', element: <Messenger /> }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      { path: 'lastLoggedIn', element: <LastLoggedIn /> },
      { path: 'userAccounts', element: <UsersAccount /> },
      { path: 'agencyAccounts', element: <AgencyAccounts /> },
      { path: 'pendingAgencyAccounts', element: <PendingAgencyAccounts /> },
      { path: 'requestAgencyAccounts', element: <RequestAgencyAccounts /> },
      { path: 'updateRequestedAgencyAccount', element: <UpdateRequestedAgencyAccount /> },
      { path: 'updatePendingAgencyAccount', element: <UpdatePendingAgencyAccount /> },
      { path: 'userSubscriptionDetail', element: <SubscriptionDetails /> },
      { path: 'paymentDetails', element: <PaymentDetails /> },
      { path: 'invoiceDetails', element: <InvoiceDetails /> },
      { path: 'invoiceDetailsList', element: <InvoiceDetailsList /> },
      { path: 'uncompletedPurchases', element: <UnCompletedPurchases /> },
      { path: 'unCompletedPurchasesDetails', element: <UnCompletedPurchasesDetails /> },
      { path: 'userAssignDetail', element: <AssignFreeUserPackage /> },
    ]
  },
  {
    path: 'engine',
    element: <SidebarLayout />,
    children: [
      { path: 'processedHistory', element: <ProcessedHistory /> },
      { path: 'upcomingQueue', element: <UpcomingQueue /> },
      { path: 'manualProcessing', element: <ManualProcessing /> },
      { path: 'performance', element: <Performance /> },
    ]
  },
  {
    path: 'archives',
    element: <SidebarLayout />,
    children: [
      { path: 'allReports', element: <AllReports /> },
    ]
  },
  {
    path: 'billingAndSubscriptions',
    element: <SidebarLayout />,
    children: [
      { path: 'overview', element: <OverView /> },
      { path: 'managePackages', element: <ManagePackages /> },
      { path: 'managePackage', element: <CreateManagePackages /> },
      { path: 'customPackage', element: <CreateCustomPackages /> },
      { path: 'manageTaxes', element: <ManageTax /> },
      { path: 'manageTax', element: <CreateManageTax /> },
      { path: 'upcomingInvoices', element: <UpcomingInvoices /> },
      { path: 'paidInvoices', element: <PaidInvoices /> },
      { path: 'delayedInvoices', element: <DelayedInvoices /> },
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      { path: '', element: <Navigate to="transactions" replace /> },
      { path: 'transactions', element: <Transactions /> },
      { path: 'createSite', element: <Create_Site /> },
      { path: 'getAllSites', element: <GetAll_Sites /> },
      { path: 'getAllUsers', element: <GetAll_Users />, },
      { path: 'getAllPaidUsers', element: <GetAllPaidUsers />, },
      { path: 'getAllLandingPageUsers', element: <GetAllLandingPageUsers />, },
      { path: 'getSurveyPageUsers', element: <GetSurveyPageUsers />, },
      { path: 'surveyDetail/:id', element: <DetailedSurveyUsers />, },
      { path: 'userProfile/:id', element: <Detailed_User />, },
      { path: 'createUser', element: <Create_User /> },
      { path: 'getAllFreeUsers', element: <GetAllFreeUsers /> },
      {
        path: 'profile',
        children: [{
          path: '',
          element: <Navigate to="details" replace />
        },
        {
          path: 'details',
          element: <UserProfile />
        },
        {
          path: 'settings',
          element: <UserSettings />
        },
        ]
      }
    ]
  },
  {
    path: 'site-management',
    element: <SidebarLayout />,
    children: [
      { path: '', element: <Navigate to="getAllOnboardedSite" replace /> },
      { path: 'getAllOnboardedSite', element: <GetAll_Sites /> },
      { path: 'getAllUsersSite', element: <UsersSites /> },
      { path: 'getAllTopSite', element: <TopSites /> },
      { path: 'getSearchLogs', element: <SearchLogsSites /> },
      { path: 'getRequestedSite', element: <Sites_Requesting /> },
      { path: 'currentQueueSites', element: <Current_Queue /> },
      { path: 'hisoricalQueueSites', element: <Historic_Queue /> },
      { path: 'userQueueList/:id', element: <User_Queue_List /> },
      { path: 'downloadReports/:id', element: <Download_Reports /> },
      { path: 'bulkUpload', element: <Bulk_Upload /> },
      { path: 'siteDetails/:id', element: <Detailed_Site /> },
      { path: 'createSite', element: <CreateSites /> },
      { path: 'mileStoneReportList/:name/:id', element: <MileStoneList /> },
      { path: 'mileStoneReport', element: <MilestoneReport /> },
      { path: 'createMileStoneReport/:id', element: <CreateMilestoneReport /> },
      { path: 'mileStoneHistory', element: <MileStoneHistory /> },
      { path: 'manageRanking/:name/:id', element: <SiteRankingFields /> },
    ]
  },
  {
    path: 'admin-management',
    element: <SidebarLayout />,
    children: [
      { path: 'admin', element: <Admin /> },
      { path: 'createAdmin', element: <CreateAdmin /> },
      { path: 'updateAdmin/:id', element: <UpdateAdmin /> },
      { path: 'rolesAndPermissions', element: <RolesAndPermissions /> },
      { path: 'configuration', element: <Add_Setting /> },
    ]
  },
  {
    path: 'master',
    element: <SidebarLayout />,
    children: [
      { path: 'countryManagement', element: <ManageCountry /> },
      { path: 'stateManagement', element: <ManageStates /> },
      { path: 'createCountyState', element: <CreateCountryState /> },
    ]
  },
  {
    path: 'algorithm',
    element: <SidebarLayout />,
    children: [
      { path: 'freeText', element: <Free_Text /> },
      { path: 'addfreeText', element: <Add_Free_Text /> },
      { path: 'questionnaire', element: <Question /> },
      { path: 'updateQuestionnaire', element: <UpdateQuestion /> },
      // { path: 'questions',  element: <Question /> },
      { path: 'scoring', element: <Scoring /> },
    ]
  },
  {
    path: 'configuration',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: 'setting', // Dynamic route with a parameter ':id'
      //   element: <Configuration /> // You need to replace 'SiteDetails' with the actual component for site details
      // },
      {
        path: 'addSetting', // Dynamic route with a parameter ':id'
        element: <Add_Setting /> // You need to replace 'SiteDetails' with the actual component for site details
      },
      {
        path: 'framework', // Dynamic route with a parameter ':id'
        element: <Framework /> // You need to replace 'SiteDetails' with the actual component for site details
      },
    ]
  },

  {
    path: 'pageVariation',
    element: <SidebarLayout />,
    children: [

      {
        path: 'addAbout',
        element: <About_us />
      },

      {
        path: 'getAllAbout',
        element: <About_us />
      },
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
];

export default routes;
