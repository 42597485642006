import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  List,
  ListItem,
  ListSubheader,
  alpha,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ThreePIcon from '@mui/icons-material/ThreeP';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import LoginIcon from '@mui/icons-material/Login';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArchiveIcon from '@mui/icons-material/Archive';
import PaymentsIcon from '@mui/icons-material/Payments';
import PreviewIcon from '@mui/icons-material/Preview';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import QueueIcon from '@mui/icons-material/Queue';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScoreIcon from '@mui/icons-material/Score';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import RttIcon from '@mui/icons-material/Rtt';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[100]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const token = localStorage.getItem('token');
  const [isAuth, setIsAuth] = useState<string | null>(token || '');

  useEffect(() => {
    if (!token) {
      setIsAuth(null);
    } else {
      setIsAuth(token);
    }
  }, [token]); // Include token as a dependency

  return (
    <>
      <MenuWrapper>
        {/* <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/overview"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Overview
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Dashboards
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/dashboard"
                  startIcon={<BrightnessLowTwoToneIcon />}
                >
                  Quick Overview
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/systemHealth"
                  startIcon={<MonitorHeartIcon />}
                >
                  System Health
                </Button>
              </ListItem>
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/messenger"
                  startIcon={<MmsTwoToneIcon />}
                >
                  Messenger
                </Button>
              </ListItem> */}
            </List>
          </SubMenuWrapper>
        </List>
        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/transactions"
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Transactions List
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Users
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/lastLoggedIn"
                  startIcon={<LoginIcon />}
                >
                  Latest logged in
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getAllUsers"
                  startIcon={<GroupAddIcon />}
                >
                  User Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getAllPaidUsers"
                  startIcon={<PaidOutlinedIcon />}
                >
                  Paid Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getAllFreeUsers"
                  startIcon={<ThreePIcon />}
                >
                  Free Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/uncompletedPurchases"
                  startIcon={<QueueIcon />}
                >
                  UnCompleted Purchases
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/requestAgencyAccounts"
                  startIcon={<AccountBalanceWalletIcon />}
                >
                  Request Agency Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/agencyAccounts"
                  startIcon={<QueueIcon />}
                >
                  Agency Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/pendingAgencyAccounts"
                  startIcon={<AccountBalanceWalletIcon />}
                >
                  Pending Agency Accounts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getAllLandingPageUsers"
                  startIcon={<GroupAddIcon />}
                >
                 Landing Page Users
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getSurveyPageUsers"
                  startIcon={<GroupAddIcon />}
                >
                Survey Users
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Sites
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/getAllTopSite"
                  startIcon={<GroupAddIcon />}
                >
                  Top Public
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/getAllUsersSite"
                  startIcon={<GroupAddIcon />}
                >
                  Users Sites
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/getSearchLogs"
                  startIcon={<PersonSearchIcon />}
                >
                  Search Logs
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Engine
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                //  disabled
                 disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  // to="/engine/processedHistory"
                  to="/site-management/hisoricalQueueSites"
                  startIcon={<WorkHistoryIcon />}
                >
                  Processed History
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                //  disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  // to="/engine/upcomingQueue"
                  to="/site-management/currentQueueSites"
                  startIcon={<QueueIcon />}
                >
                  Upcoming Queue
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/engine/manualProcessing"
                  startIcon={<RunningWithErrorsIcon />}
                >
                  Manual Processing
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/engine/performance"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Performance
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Archives
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/archives/allReports"
                  startIcon={<ArchiveIcon />}
                >
                  All reports
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Billing and Subscriptions
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/overview"
                  startIcon={<PreviewIcon   />}
                >
                  Overview
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/managePackages"
                  startIcon={<InventoryIcon   />}
                >
                  Manage Packages
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/manageTaxes"
                  startIcon={<RttIcon   />}
                >
                  Manage Taxes
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/upcomingInvoices"
                  startIcon={<ReceiptIcon   />}
                >
                  Upcoming Invoices
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/paidInvoices"
                  startIcon={<PaymentsIcon   />}
                >
                  Paid Invoices
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/billingAndSubscriptions/delayedInvoices"
                  startIcon={<DisplaySettingsTwoToneIcon   />}
                >
                  Delayed Invoices
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Masters Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/master/countryManagement"
                  startIcon={<ReceiptIcon />}
                >
                 Manage Country
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                //  disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/master/stateManagement"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Manage State
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Algorithm
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/algorithm/freeText"
                  startIcon={<ReceiptIcon />}
                >
                  Free Text Mapping
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                 className='fw-bold'
                //  disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/algorithm/questionnaire"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Questionnaire
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/algorithm/scoring"
                  startIcon={<ScoreIcon />}
                >
                  Scoring
                </Button>
              </ListItem>
              {/* <ListItem component="div">
                <Button 
                 className='fw-bold'
                //  disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/algorithm/questions"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Questions
                </Button>
              </ListItem> */}
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/admin-management/admin"
                  startIcon={<AccountCircleIcon />}
                >
                  Administrators
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                 disabled
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/admin-management/rolesAndPermissions"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Roles & Permissions
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button 
                  className='fw-bold'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/admin-management/configuration"
                  startIcon={<ReceiptIcon />}
                >
                  Configuration (SMTP)
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/getAllUsers"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Users
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/admin-management/admin"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Administrator
                </Button>
              </ListItem>
              <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/management/getAllSites"
                startIcon={<LanguageIcon />}
              >
                Site Management
              </Button>
            </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Sites
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/getAllTopSite"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Top Sites
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/getAllUsersSite"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Users Sites
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/bulkUpload"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Bulk Upload
                </Button>
              </ListItem>
              <ListItem component="div">
              <Button className='fw-light'
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/site-management/getAllOnboardedSite"
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Sites Onboarded
              </Button>
            </ListItem>


              <ListItem component="div">
              <Button className='fw-light'
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/site-management/getRequestedSite"
                startIcon={<LanguageIcon />}
              >
                Sites Requested
              </Button>
            </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Monitoring
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/currentQueueSites"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Current Queue
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/site-management/hisoricalQueueSites"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Historical Queue
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}


        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Admin
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/admin-management/admin"
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Admin
              </Button>
            </ListItem>
             </List>
          </SubMenuWrapper>
        </List> */}


        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky className='DashTitle'>
              Algorithms
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button className='fw-light'
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/algorithm/freeText"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Free Text Mappings
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}


        {/* <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky className='DashTitle'>
                Configuration
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button className='fw-light'
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/configuration/addSetting"
                    startIcon={<DisplaySettingsTwoToneIcon />}
                  >
                    Settings
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button className='fw-light'
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/configuration/framework"
                    startIcon={<DisplaySettingsTwoToneIcon />}
                  >
                    Framework
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List> */}



        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Page Variations
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/pageVariation/getContact"
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Contact Us
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/pageVariation/addAbout"
                startIcon={<LanguageIcon />}
              >
                About Us
              </Button>
            </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Components
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/buttons"
                  startIcon={<BallotTwoToneIcon />}
                >
                  Buttons
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/modals"
                  startIcon={<BeachAccessTwoToneIcon />}
                >
                  Modals
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/accordions"
                  startIcon={<EmojiEventsTwoToneIcon />}
                >
                  Accordions
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/tabs"
                  startIcon={<FilterVintageTwoToneIcon />}
                >
                  Tabs
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/badges"
                  startIcon={<HowToVoteTwoToneIcon />}
                >
                  Badges
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/tooltips"
                  startIcon={<LocalPharmacyTwoToneIcon />}
                >
                  Tooltips
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/avatars"
                  startIcon={<RedeemTwoToneIcon />}
                >
                  Avatars
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/cards"
                  startIcon={<SettingsTwoToneIcon />}
                >
                  Cards
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/forms"
                  startIcon={<TrafficTwoToneIcon />}
                >
                  Forms
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Extra Pages
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/404"
                  startIcon={<CheckBoxTwoToneIcon />}
                >
                  Error 404
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/500"
                  startIcon={<CameraFrontTwoToneIcon />}
                >
                  Error 500
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/coming-soon"
                  startIcon={<ChromeReaderModeTwoToneIcon />}
                >
                  Coming Soon
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/maintenance"
                  startIcon={<WorkspacePremiumTwoToneIcon />}
                >
                  Maintenance
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
