import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { callApi } from 'src/action/RestClient';
import { notify } from 'src/common/toastMesaage';
import { ToastContainer } from 'react-toastify'


const DetailedSurveyUsers = () => {
    const params = useParams();
    const [userDetail, setUserDetail] = useState();
    const [userLoading, setUserLoading] = useState(false);

    const getUserData = async () => {
        setUserLoading(true);
        try {

            const response = await callApi('GET', `/get/survey/${params?.id}`);
            setUserDetail(response?.data?.data?.length > 0 && response?.data?.data[0]);

        } catch (error) {
            notify(error, "error");
            console.error('Error:', error);
        } finally {
            setUserLoading(false);
        }
    };

    useEffect(() => {
        if (params?.id) {
            getUserData();
        }
    }, [params])

    return <>

        <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
            <div role="presentation" style={{ marginTop: '20px' }} >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        href="/management/getSurveyPageUsers"
                    >
                        <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Survey Page Users
                    </Link>
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {userDetail?.name}
                    </Typography>
                </Breadcrumbs>
            </div>
        </section>
        <PageTitleWrapper>
            <PageHeader title="CEO Director Contact Detail" />
        </PageTitleWrapper>
        <div className='col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0' style={{ backgroundColor: 'white', height: 'fit-content', margin: '25px', width: '95%', boxShadow: "10px 10px 10px 10px #e8eef3", borderRadius: "0.25rem", padding: '25px', gap: '5px', display: 'flex', flexDirection: 'column' }}>
            <div>
                <h5 className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ fontWeight: 'bold' }}>  {userDetail?.name}</h5>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.email}</p>
            </div>

            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.phone}</p>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.organization}</p>
            </div>

        </div>
        <PageTitleWrapper>
            <PageHeader title="IT Head Contact Detail" />
        </PageTitleWrapper>
        <div className='col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0' style={{ backgroundColor: 'white', height: 'fit-content', margin: '25px', width: '95%', boxShadow: "10px 10px 10px 10px #e8eef3", borderRadius: "0.25rem", padding: '25px', gap: '5px', display: 'flex', flexDirection: 'column' }}>
            <div>
                <h5 className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ fontWeight: 'bold' }}>  {userDetail?.it_head_name}</h5>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.it_head_email}</p>
            </div>

            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.it_head_phone}</p>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.it_head_organization}</p>
            </div>

        </div>
        <PageTitleWrapper>
            <PageHeader title="Manager / Associate Manager IT Contact Detail" />
        </PageTitleWrapper>
        <div className='col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0' style={{ backgroundColor: 'white', height: 'fit-content', margin: '25px', width: '95%', boxShadow: "10px 10px 10px 10px #e8eef3", borderRadius: "0.25rem", padding: '25px', gap: '5px', display: 'flex', flexDirection: 'column' }}>
            <div>
                <h5 className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ fontWeight: 'bold' }}>  {userDetail?.manger_name}</h5>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.manger_email}</p>
            </div>

            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.manger_phone}</p>
            </div>
            <div>
                <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ color: 'gray', fontWeight: 600 }}>{userDetail?.manger_organization}</p>
            </div>

        </div>
        <PageTitleWrapper>
            <PageHeader title="Survey Questions:" />
        </PageTitleWrapper>
        <div class="col-lg-12">
            <div class="missionBox mb-4">
                <div class="col-lg-12">
                    <div class="missionBox mb-4">
                        <form class="p-4">

                            <div class="mb-4">
                                <div class="mb-3">
                                    <label for="q1" class="form-label">1. How would you rate the current state of your
                                        college’s IT infrastructure in terms of reliability, performance, and
                                        scalability?</label>
                                    <label class="form-control" name="surveys_answer_first" id="q1" rows="2"
                                    > {userDetail?.surveys_answer_first}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q2" class="form-label">2. To what extent has your college adopted AI
                                        technologies to enhance teaching methodologies and improve student learning
                                        experiences?</label>
                                    <label class="form-control" name="surveys_answer_second" id="q2" rows="2"
                                    > {userDetail?.surveys_answer_second}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q3" class="form-label">3. What are the key challenges you face in
                                        implementing and maintaining advanced IT solutions in your institution?</label>
                                    <label class="form-control" name="surveys_answer_thired" id="q3" rows="2"
                                    > {userDetail?.surveys_answer_thired}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q4" class="form-label">4. How do you currently use AI in creating and
                                        managing educational content for courses?</label>
                                    <label class="form-control" name="surveys_answer_fourth" id="q4" rows="2"
                                    > {userDetail?.surveys_answer_fourth}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q5" class="form-label">5. What is your institution’s approach to data
                                        security and privacy in relation to AI technologies?</label>
                                    <label class="form-control" name="surveys_answer_fifth" id="q5" rows="2"
                                    > {userDetail?.surveys_answer_fifth}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q6" class="form-label">6. How do you evaluate the effectiveness of
                                        AI-based systems in improving student engagement and academic
                                        performance?</label>
                                    <label class="form-control" name="surveys_answer_six" id="q6" rows="2"
                                    > {userDetail?.surveys_answer_six}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q7" class="form-label">7. What type of AI applications have you
                                        implemented in administrative tasks such as student admission, course
                                        scheduling, or resource management?</label>
                                    <label class="form-control" name="surveys_answer_seven" id="q7" rows="2"
                                    > {userDetail?.surveys_answer_seven}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q8" class="form-label">8. Do you think AI-based tools can play a
                                        significant role in addressing the challenges faced by your institution in terms
                                        of student retention and graduation rates?</label>
                                    <label class="form-control" name="surveys_answer_eight" id="q8" rows="2"
                                    > {userDetail?.surveys_answer_eight}</label>
                                </div>

                                <div class="mb-3">
                                    <label for="q9" class="form-label">9. How do you ensure that your AI systems align
                                        with your institution’s values and ethical standards?</label>
                                    <label class="form-control" name="surveys_answer_nine" id="q9" rows="2"
                                    > {userDetail?.surveys_answer_nine}</label>
                                </div>


                                <div class="mb-3">
                                    <label for="q10" class="form-label">10. What are your future plans for adopting AI
                                        technologies to enhance the quality of education at your institution?</label>
                                    <label class="form-control" name="surveys_answer_ten" id="q10" rows="2"
                                    > {userDetail?.surveys_answer_ten}</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default DetailedSurveyUsers
